import React, { useState } from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import {  Button } from 'primereact/button';
type ViewSwitcherProps = {
    onViewModeChange: (viewMode: ViewMode) => void;
};

const viewModes = [
    ViewMode.Day,
    ViewMode.Week,
    ViewMode.Month
];

export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
    onViewModeChange,
}) => {
    const [currentViewModeIndex, setCurrentViewModeIndex] = useState(0); // Start with Day view

    const handleZoomIn = () => {
        if (currentViewModeIndex > 0) {
            setCurrentViewModeIndex(currentViewModeIndex - 1);
            onViewModeChange(viewModes[currentViewModeIndex - 1]);
        }
    };

    const handleZoomOut = () => {
        if (currentViewModeIndex < viewModes.length - 1) {
            setCurrentViewModeIndex(currentViewModeIndex + 1);
            onViewModeChange(viewModes[currentViewModeIndex + 1]);
        }
    };

    return (
        <div className="flex justify-content-end align-items-center">

            
            <div className="inline-flex">

                <Button icon="pi pi-minus" className="m-2" rounded onClick={handleZoomIn} disabled={currentViewModeIndex === 0} text raised aria-label="Zoom In" />

                <Button icon="pi pi-plus" className="m-2" rounded onClick={handleZoomOut}  disabled={currentViewModeIndex === viewModes.length - 1} text raised aria-label="Zoom Out" />

            </div>
        </div>
    );
};