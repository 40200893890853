// apiService.ts
import axios from 'axios';
import { API_BASE_URL } from './config';
import { CustomTask, DataResponse, Item } from './helper';

export const fetchProjectList = async (): Promise<Item[]> => {
    try {
        const response = await axios.get(`${API_BASE_URL}/ermeo/projectlist`);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching project list:', error);
        throw error;
    }
};

export const fetchDropdownData = async (id: string, type: string): Promise<Item[]> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/ermeo/list`, { id, type });
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown data:', error);
        throw error;
    }
};

export const fetchTaskChartData = async (parentId: string, timeFrame: string): Promise<DataResponse> => {
    try {
        const response = await axios.get(`${API_BASE_URL}/ermeo/task-chart-data`, {
            params: { parent_id: parentId, time_frame: timeFrame },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching chart data:', error);
        throw error;
    }
};

export const fetchTasks = async (id: string): Promise<CustomTask[]> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/ermeo/task`, { id, type: 'project' });
        return response.data;
    } catch (error) {
        console.error('Error fetching tasks:', error);
        throw error;
    }
};

export const updateTask = async (taskData: { start: string; end: string; id: string; type: string }): Promise<void> => {
    try {
        await axios.post(`${API_BASE_URL}/ermeo/updatetask`, taskData);
    } catch (error) {
        console.error('Error updating task:', error);
        throw error;
    }
};

export const updateTaskWithProgress = async (taskData: { start: Date; end: Date; id: string; progress: number }): Promise<void> => {
    try {
        await axios.post(`${API_BASE_URL}/ermeo/updatetask`, taskData);
    } catch (error) {
        console.error('Error updating task with progress:', error);
        throw error;
    }
};
