// src/components/ChartComponent.tsx
import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
interface ChartComponentProps {
    timeFrame: string;
    setTimeFrame: (data:string) => void;
    data: any; // You should replace 'any' with your actual data type
    histogramloading: boolean;
}
export const options = {
    plugins: {
        title: {
            display: true,
            text: 'Involvement Per Discipline',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: false,
            ticks: {
                maxRotation: 0,
                minRotation: 0
            }
        },
        y: {
            stacked: false,
        },
    },
};
const ChartComponent: React.FC<ChartComponentProps> = ({ data, histogramloading, timeFrame, setTimeFrame }) => {
    if (histogramloading) {
        return <div className="flex justify-content-center align-items-center"><ProgressSpinner /></div>;
    }
    return data !== null && data !== undefined ? (
        <div className="m-1 grid justify-content-between">
            <div className="col-12 md:col-6">
                <Dropdown
                    value={timeFrame}
                    optionLabel="label"
                    options={[
                        { label: 'Daily', value: 'daily' },
                        { label: 'Weekly', value: 'weekly' },
                        { label: 'Monthly', value: 'monthly' }
                    ]}
                    onChange={(e) => setTimeFrame(e.value)}
                />
                <Bar options={options} data={data} />
            </div>
            <div className="col-12 md:col-6 mt-4">
                <DataTable value={data.datasets}>
                    <Column field="label" header="Discipline" body={(rowData) => (
                        <>
                            <span style={{
                                backgroundColor: rowData.backgroundColor,
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                                verticalAlign: 'middle'
                            }}></span>
                            {rowData.label}
                        </>
                    )} />
                    {data.labels.map((label:any, index: number) => (
                        <Column key={index} header={label} body={(rowData: any) => rowData.data[index]} />
                    ))}
                </DataTable>
            </div>
        </div>
    ) : data === null ?<div className="flex justify-content-center align-items-center"><h6>No Histogram Available</h6></div>:<></>;
};

export default ChartComponent;
