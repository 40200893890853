import { Task } from 'gantt-task-react';
import { Button } from 'primereact/button';
interface TaskListTableProps {
    rowHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    tasks: any[];
    onExpanderClick: (task: Task) => void;
    handleAddTask: (task: Task) => void;
    setDate: (task: Task) => void;
};
const CustomTaskListTable: React.FC<TaskListTableProps> = ({ tasks, rowHeight, rowWidth, fontFamily, fontSize, onExpanderClick, setDate, handleAddTask }) => {
    return (
        <div className="task-list-table">
            {tasks.map((task, i) => {
                const isProject = task.type === "project";

                return (
                    <div
                        key={task.id}
                        className="task-list-item"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: rowHeight,
                            fontFamily: fontFamily,
                            fontSize: fontSize,
                            background: i % 2 === 0 ? "#ffffff" : "#f4f5f7",
                            wordWrap: 'break-word',
                            borderBottom: '1px solid #ccc',
                        }}
                    >
                        <span className="list-item" style={{
                            width: '200px',
                            textAlign: isProject ? 'justify' : 'right',
                        }}> <p title={isProject ? parseInt(task.name) : task.name} onClick={() => {
                            onExpanderClick(task);

                            }}>{isProject ? (task.hideChildren ? "► " : "▼ ") : "   "} {isProject ? parseInt(task.name) : task.name} </p>
                            {/* {isProject && (
                  <div
                    style={{
                      display: "inline",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 20,
                      height: 20,
                      padding: "3px",
                      backgroundColor: "#dfe1e5",
                      borderRadius: 5
                    }}
                    onClick={() => handleAddTask(task)}
                  >
                    +
                  </div>
            )} */}
                        </span>
                        {task.dateFlag && isProject ? (<div className="list-item" style={{ width: rowWidth }}><Button severity="danger" onClick={() => setDate(task)} label="Add Date"/>
                            </div>) : (
                            <div style={{ display: 'contents' }}><span className="list-item" style={{ width: rowWidth }}>{task.start.toLocaleDateString()}</span>
                                <span className="list-item" style={{ width: rowWidth }}>{task.end.toLocaleDateString()}</span></div>
                        )}

                        <span className="list-item" style={{ width: rowWidth }}>{task.durations}</span>
                    </div>
                );
            })}
        </div>
    );
};
export default CustomTaskListTable;