// src/components/TaskModal.tsx
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import React from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { CustomTask } from '../helper';

interface TaskModalProps {
    show: boolean;
    onHide: () => void;
    taskId: CustomTask | null;
    fetchData: () => void;
}

const TaskModal: React.FC<TaskModalProps> = ({ show, onHide, taskId, fetchData }) => {
    // State to manage the date inputs
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');


    // Handle date changes
    const handleStartDateChange = (e: any) => setStartDate(e.target.value);
    const handleEndDateChange = (e: any) => setEndDate(e.target.value);

    // Handle form submission
    const handleSubmit = async () => {
        const data = {
            start: startDate,
            end: endDate,
            id: taskId?.id,
            type: "jc"
        };

        try {
            const res = await axios.post(`${API_BASE_URL}/ermeo/updatetask`, data);
            console.log(res.data);
        } catch (error) {
            console.error('There was a problem with the Axios operation:', error);
        } finally {
            fetchData();
            onHide(); // Close the modal after submission
        }
    };
    console.log(show)
    
    return (
        <Dialog
            header={`Set Date ${taskId?.name}`}
            visible={show}
            style={{ width: '50vw' }}
            onHide={() => onHide()}
            footer={
                <>
                    <Button label="Submit" icon="pi pi-check" onClick={handleSubmit} />
                    <Button label="Cancel" icon="pi pi-times" onClick={() => onHide()} className="p-button-danger" />
                </>
            }
        >
            <div className="field">
                <label htmlFor="startDate">Start Date</label>
                <input id="startDate" type="date" className="p-inputtext p-component" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </div>
            <div className="field">
                <label htmlFor="endDate">End Date</label>
                <input id="endDate" type="date" className="p-inputtext p-component" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </div>
        </Dialog>
    );
};

export default TaskModal;
