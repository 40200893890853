import React, { useEffect, useRef , useState } from 'react';
import { ViewMode } from 'gantt-task-react';
import { CustomTask, DataResponse, Item } from './helper';
import 'gantt-task-react/dist/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import { fetchProjectList, fetchDropdownData, fetchTaskChartData, fetchTasks, updateTask, updateTaskWithProgress } from './Api';
import DropdownComponent from './components/Dropdowns';
import GanttChartComponent from './components/GanttChart';
import ChartComponent from './components/ChartComponent';
import SaveDataModal from './components/SaveModal';
import TaskModal from './components/TaskModal';

const App = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  const [taskDisplay, setTaskDisplay] = useState<CustomTask[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGanttLoading, setIsGanttLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [histogramLoading, setHistogramLoading] = useState(false);
  const [timeFrame, setTimeFrame] = useState<string>("weekly");
  const [firstDropdownData, setFirstDropdownData] = useState<Item[]>([]);
  const [secondDropdownData, setSecondDropdownData] = useState<Item[]>([]);
  const [thirdDropdownData, setThirdDropdownData] = useState<Item[]>([]);
  const [selectedFirst, setSelectedFirst] = useState<Item>();
  const [selectedSecond, setSelectedSecond] = useState<Item>();
  const [selectedThird, setSelectedThird] = useState<Item>();
  const [data, setData] = useState<DataResponse | null | undefined>(undefined);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedTaskId, setSelectedTaskId] = React.useState<CustomTask | null>(null);
  const prevselectedSecond = useRef<Item>();
  const prevselectedFirst = useRef<Item>();
  const prevTimeFrame= useRef<string>();
  const prevselectedThird= useRef<Item>();
  useEffect(() => {
    fetchProjectList()
      .then(data => {
        console.log(data);
        setFirstDropdownData(data)})
      .catch(error => console.error('Error fetching project list:', error))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (selectedFirst && prevselectedFirst.current !== selectedFirst) {
      fetchDropdownData(selectedFirst.id, "project")
        .then(data => {
          setSecondDropdownData(data)
          setTaskDisplay([]);
          setSelectedThird(undefined);
          setSelectedSecond(undefined);
          setData(undefined);
          setThirdDropdownData([]);
        })
        .catch(error => console.error('Error fetching second dropdown data:', error));
    }
    if (selectedFirst && (prevselectedFirst.current !== selectedFirst || prevTimeFrame.current !== timeFrame )) {
      setHistogramLoading(true);
      fetchTaskChartData(selectedFirst.id, timeFrame)
        .then(data => setData(data))
        .catch(error => console.error('Error fetching chart data:', error))
        .finally(() => setHistogramLoading(false));
    }
    prevTimeFrame.current = timeFrame;
    prevselectedFirst.current = selectedFirst;
  }, [selectedFirst, timeFrame]);

  useEffect(() => {
    if (selectedSecond && prevselectedSecond.current !== selectedSecond) {
      fetchDropdownData(selectedSecond.id, "workpack")
        .then(data => {
          setThirdDropdownData(data);
          setTaskDisplay([]);
          setSelectedThird(undefined);
        })
        .catch(error => console.error('Error fetching third dropdown data:', error));

    }
    if (selectedSecond && (prevselectedSecond.current !== selectedSecond || prevTimeFrame.current !== timeFrame)) {
      setHistogramLoading(true);
      fetchTaskChartData(selectedSecond.id, timeFrame)
        .then(data => setData(data))
        .catch(error => {
          console.error('Error fetching chart data:', error);
          setData(null);
        })
        .finally(() => setHistogramLoading(false));
    }
    prevselectedSecond.current =  selectedSecond;
    prevTimeFrame.current = timeFrame;
  }, [selectedSecond, timeFrame]);

  useEffect(() => {
    if (selectedThird && prevselectedThird.current !== selectedThird) {
      setIsGanttLoading(true);
      fetchTaskData(selectedThird.id);
    }

    if (selectedThird && (prevselectedThird.current !== selectedThird || prevTimeFrame.current !== timeFrame)) {
      setHistogramLoading(true);
      fetchTaskChartData(selectedThird.id, timeFrame)
        .then(data => setData(data))
        .catch(error => console.error('Error fetching chart data:', error))
        .finally(() => setHistogramLoading(false));
    }

    prevselectedThird.current = selectedThird;
    prevTimeFrame.current = timeFrame;
  }, [selectedThird, timeFrame]);

  const setDate = (task: any) => {
    setSelectedTaskId(task);
    setModalShow(true);
  }
  const fetchTaskData = (data:string)=>{
    fetchTasks(data)
      .then(data => {
        const tasksWithDates = data.map(task => ({
          ...task,
          start: task.start ? new Date(task.start) : new Date(),
          end: task.end ? new Date(task.end) : new Date(new Date().getTime() + 86400000),
        }));
        setTaskDisplay(tasksWithDates);
      })
      .catch(error => console.error('Error fetching tasks:', error))
      .finally(() => setIsGanttLoading(false));
  }
  const handleTaskChange = (task: CustomTask) => {
    const newTasks = taskDisplay.map(t => (t.id === task.id ? task : t));
    setTaskDisplay(newTasks);
    updateTaskWithProgress({
      start: task.start,
      end: task.end,
      id: task.id,
      progress: task.progress
    }).catch(error => console.error('Error updating task with progress:', error));
  };
  const onExpanderClick = (task: any) => {
    console.log("On expander click Id:" + task.id);
    setTaskDisplay(taskDisplay.map(t => (t.id === task.id ? task : t)));
  };

  return (
    <div>
      <DropdownComponent
        firstDropdownData={firstDropdownData}
        secondDropdownData={secondDropdownData}
        thirdDropdownData={thirdDropdownData}
        setSelectedFirst={setSelectedFirst}
        setSelectedSecond={setSelectedSecond}
        setSelectedThird={setSelectedThird}
        selectedFirst={selectedFirst}
        selectedSecond={selectedSecond}
        selectedThird={selectedThird}
      />
      <div className="block">
        <ChartComponent
          timeFrame={timeFrame}
          data={data}
          histogramloading={histogramLoading} setTimeFrame={setTimeFrame }       
        />
      </div>
        <GanttChartComponent
          taskdisplay={taskDisplay}
          isganttLoading={isGanttLoading}
          handleTaskChange={handleTaskChange}
          view={view}
          setView={setView}
          handleTaskDelete={() => { return true }}
          handleProgressChange={() => { }}
          handleDblClick={() => { }}
          handleClick={() => { }}
          handleSelect={() => { }}
          onExpanderClick={onExpanderClick}
          handleAddTask={() => { }}
          setDate={setDate} />
      <TaskModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        taskId={selectedTaskId}
        fetchData={()=>{
          if (selectedThird){
            fetchTaskData(selectedThird?.id);
          }
        }}
      />
      <SaveDataModal show={showModal} handleClose={() => setModalShow(false)} taskId={selectedTaskId}
        fetchData={fetchProjectList} />
    </div>
  );
};

export default App;
