// src/components/GanttChart.tsx
import React from 'react';
import { Gantt, ViewMode } from 'gantt-task-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import CustomTaskListHeader from './CustomHeader';
import CustomTaskListTable from './CustomTaskListTable';
import { ViewSwitcher } from './view-switcher';
import { CustomTask } from '../helper';

interface GanttChartProps {
    taskdisplay: CustomTask[];
    view: ViewMode;
    setView: React.Dispatch<React.SetStateAction<ViewMode>>;
    isganttLoading: boolean;
    handleTaskChange: (task: any) => void;
    handleTaskDelete: (task: any) => boolean;
    handleProgressChange: (task: any) => void;
    handleDblClick: (task: any) => void;
    handleClick: (task: any) => void;
    handleSelect: (task: any, isSelected: boolean) => void;
    onExpanderClick: (task: any) => void;
    handleAddTask: (task: any) => void;
    setDate: (task: any) => void;
}

const GanttChart: React.FC<GanttChartProps> = ({
    taskdisplay, view, setView, isganttLoading, handleTaskChange,
    handleTaskDelete, handleProgressChange, handleDblClick, handleClick,
    handleSelect, onExpanderClick, handleAddTask, setDate
}) => {
    if (isganttLoading) {
        return <div className="flex justify-content-center align-items-center"><ProgressSpinner /></div>;
    }
    return taskdisplay.length !== 0 ?(
        <div className='ml-2 mb-10'>
            <ViewSwitcher onViewModeChange={setView} />
            <Gantt
                tasks={taskdisplay}
                viewMode={view}
                onDateChange={handleTaskChange}
                onDelete={handleTaskDelete}
                onProgressChange={handleProgressChange}
                onDoubleClick={handleDblClick}
                onClick={handleClick}
                onSelect={handleSelect}
                onExpanderClick={onExpanderClick}
                TaskListHeader={CustomTaskListHeader}
                listCellWidth={true ? "90px" : ""}
                columnWidth={70}
                TaskListTable={(props) => (
                    <CustomTaskListTable {...props} handleAddTask={handleAddTask} setDate={setDate} />
                )}
            />
        </div>
    ):<></>;
};

export default GanttChart;
