import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { CustomTask } from '../helper';
import axios from 'axios';
import { API_BASE_URL } from '../config';

interface SaveDataModalProps {
    show: boolean;
    handleClose: () => void;
    taskId: CustomTask | null,
    fetchData: () => void;
    // Explicitly define onHide as a function that takes no arguments and returns void
}
const SaveDataModal: React.FC<SaveDataModalProps> = ({
    show, handleClose, taskId, fetchData }) => {

    const [rangeValue, setRangeValue] = useState(taskId?.progress || 0);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // Update state when taskId changes
        if (taskId) {
            setRangeValue(taskId.progress);
        }
    }, [taskId]);
    const saveData = async () => {
        const data = {
            progress: rangeValue,
            id: taskId?.id,
            type: "jcprogress"
        };

        try {
            const res = await axios.post(`${API_BASE_URL}/ermeo/updatetask`, data);
            console.log(res.data);
        } catch (error) {
            console.error('There was a problem with the Axios operation:', error);
        } finally {
            fetchData();
            handleClose(); // Close the modal after submission
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Set Completion Progress For {taskId?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Set Progress For</Form.Label>
                        <Form.Control
                            type="range"
                            value={rangeValue}
                            onChange={(e) => setRangeValue(parseInt(e.target.value))}
                            min="0"
                            step="10"
                            max="100"
                        />
                        <Form.Text>Value: {rangeValue}</Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={isSaving}>
                    Close
                </Button>
                <Button variant="primary" onClick={saveData} disabled={isSaving}>
                    {isSaving ? 'Saving...' : 'Save Changes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SaveDataModal;